import { useEffect } from "react";

import { withAuthenticationRequired } from "@auth0/auth0-react";
import { useTenantSetting } from "@nestoca/multi-tenant";
import { Flex, Typography, Box } from "@nestoca/ui";
import { useGetAccount } from "@shared/api/hooks/account";
import { useGetApplicationsByApplicantId } from "@shared/api/hooks/applications";
import {
  HomePage,
  Header,
  NoActiveApplications,
  HomeHeader,
  HomePageGAQ,
} from "@shared/ui";
import { useApplicationContext } from "@shared/ui/components/application-context";
import { commonServerProps } from "@shared/utils/common-server-props";
import { useTranslation } from "next-i18next";

import { withApplication } from "@lib/with-application";

import styles from "./index.module.scss";

import type { NextPageWithLayout } from "@pages/_app";
import type { GetServerSideProps } from "next";

const Home = () => {
  const { t } = useTranslation("common");
  const { setApplication } = useApplicationContext();

  const { data: account, isLoading: isLoadingAccount } = useGetAccount();
  const { data: applications, isLoading: isLoadingApplications } =
    useGetApplicationsByApplicantId();
  const { value: enableGaq } = useTenantSetting("enableGaq");

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setApplication({}), []);

  const hasNoApplications = applications && applications.length === 0;

  if (isLoadingAccount || isLoadingApplications) {
    return null;
  }

  if (hasNoApplications) {
    return enableGaq ? (
      <Box className={styles.page}>
        <HomeHeader className={styles.header} />
        <Flex className={styles["page-body"]}>
          <Typography className={styles["header-description"]}>
            {t("startApplicationProcess")}
          </Typography>
          <HomePageGAQ />
        </Flex>
      </Box>
    ) : (
      <NoActiveApplications />
    );
  }

  return (
    <HomePage
      getApplicationUrl={(application) => {
        return `/applications/${application.id}/applicant/${account?.id}`;
      }}
    />
  );
};

export const getServerSideProps: GetServerSideProps = async (context) => {
  return commonServerProps({
    context,
    namespaces: ["common", "applications", "form", "type-selector"],
  });
};

// const Page: NextPageWithLayout = withAuthenticationRequired(
//   withPermissions(["list:documents?????"], Home)
// );

const Page: NextPageWithLayout<never> = withAuthenticationRequired(
  withApplication(Home)
);

Page.getLayout = (page) => (
  <main className="bg-default-muted">
    <Header />
    {page}
  </main>
);

export default Page;
